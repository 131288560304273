<template>
  <section class="section">
    <div>
      Redirecting...
    </div>
  </section>
</template>

<script>
export default {
  name: 'Dashboard',
  mounted () {
    if (this.$store.state.user.superuser) {
      this.$router.push('/subscriptions')
    } else if (this.$store.state.user.dictionary_editor) {
      this.$router.push('/dictionary')
    } else {
      this.$router.push('/noaccess')
    }
  }
}
</script>
